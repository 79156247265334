<template>

  <editor
      api-key="h14dr65riplg5vt5lwtib14wla6jmcefetxf7vm8m0uhjcno"
      :init="{
         height: 500,
         menubar: false,
         auto_focus: true,
         branding: false,
         automatic_uploads: false,
         language:'zh_CN',//注意大小写
         // selector: '#tinydemo',

          selector: 'textarea',
           plugins: [
             'table advtable',
             'autolink',
             'textpattern',
             'image imagetools',
             'restoredraft',
             'advtable',
             'a11ychecker',
             'advcode',
             'image',
             'permanentpen',
             'autosave',
             'link',
             'code',
             'quickbars',
             'media',
             'table',
             'preview',
             'textpattern',

           ],
textpattern_patterns:[
      {start: '*', end: '*', format: 'italic'},
      {start: '**', end: '**', format: 'bold'},
      {start: '#', format: 'h1'},
      {start: '##', format: 'h2'},
      {start: '###', format: 'h3'},
      {start: '####', format: 'h4'},
      {start: '#####', format: 'h5'},
      {start: '######', format: 'h6'},
      {start: '1. ', cmd: 'InsertOrderedList'},
      {start: '* ', cmd: 'InsertUnorderedList'},
      {start: '- ', cmd: 'InsertUnorderedList' }
    ],
           autosave_interval: '20s',
           images_upload_url: '/demo/upimg.php',
           images_upload_base_path: '/demo',

           toolbar1:
             'undo redo code visualaid \
              strikethrough selectall remove lineheight fontsize alignnone \
              aligncenter alignjustify blocks fontfamily indent  a11ycheck fontsizeselect\
              alignleft media  forecolor  \
            ',
             toolbar2:
             ' link bold backcolor table tabledelete wordcount pagebreak \
            formatpainter autolink  cut paste image editimage underline superscript subscript italic\
             copy permanentpen restoredraft removeformat pastetext media preview blockquote',
             // toolbar3:
             // 'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
         }"
      v-model="content"
      output-format="html"
      @selectionChange="handlerFunction"

  />


</template>

<script>
import Editor from '@tinymce/tinymce-vue'

import { ref, watch,onMounted} from "vue"
export default {
  name: 'Ed',
  components: {
    'editor': Editor
  },
  emits:["getCon"],
  props:["ct"],
  setup(props,context){
    const content = ref()
    onMounted(()=>{
      content.value = props.ct
    })


    watch(content,(newValue)=>{
      context.emit("getCon",newValue)


    })


    const handlerFunction = (event,editor)=>{
      console.log(event,editor,"kk看看")

    }

    return {
      content,
      handlerFunction


    }
  }


}
</script>
<style scoped>
@import url("../../assets/skins/ui/oxide/skin.css");
@import url("../../assets/skins/ui/oxide/content.css");
</style>
